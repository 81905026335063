html{
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: 'Open Sans', sans-serif; */
  height: 100%;
  width: 100%;
  overflow: hidden;
}
