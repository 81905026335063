
  .webRTCPlayerRoomContainer {
    height: inherit;
  }
  .webRTCPlayerRoomContainerBounds {
    background-color: #2b3345;
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    bottom: 0;
    height: inherit;
    min-width: 400px !important;
    width: inherit;
  }
  .bodyclassWebcamOnAir {
    background: url('../../assets/images/polling-video-large.gif') #000000 no-repeat center center;
    background-attachment: fixed;

  }
  .bodyclasswebcam1 {
    background: url('../../assets/images/background_webcam1.png') #000000 no-repeat center center;
    background-attachment: fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    background-size: contain;
    
    
}


.bodyclasswebcam2 {
    background: url('../../assets/images/background_webcam2.png') #000000 no-repeat center center;
    background-attachment: fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    background-size: contain;
}

.bodyclasswebcam3 {
    background: url('../../assets/images/background_webcam3.png') #000000 no-repeat center center;
    background-attachment: fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    background-size: contain;
}
.bodyclasswebcam4 {
    background: url('../../assets/images/background_webcam4.png') #000000 no-repeat center center;
    background-attachment: fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    background-size: contain;
}
.bodyclasswebcam5 {
    background: url('../../assets/images/background_webcam5.png') #000000 no-repeat center center;
    background-attachment: fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    background-size: contain;
}
.bodyclasswebcam6 {
    background: url('../../assets/images/background_webcam6.png') #000000 no-repeat center center;
    background-attachment: fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    background-size: contain;
}
.bodyclasswebcam7 {
    background: url('../../assets/images/background_webcam7.png') #000000 no-repeat center center;
    background-attachment: fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    background-size: contain;
}
.bodyclasswebcam8 {
    background: url('../../assets/images/background_webcam8.png') #000000 no-repeat center center;
    background-attachment: fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    background-size: contain;
}

.bodyclassstreamer {
    background: url('../../assets/images/background_streamer.png') #000000 no-repeat center center;
    background-attachment: fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    background-size: contain;

}
.bodyclassscreenmonitor {
   background: url('../../assets/images/background_screen_monitor.png') #000000 no-repeat center center;
    background-attachment: fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    background-size: contain;

}
.bodyclassmonitor {
    background: url('../../assets/images/background_monitor.png') #000000 no-repeat center center;
    background-attachment: fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    background-size: contain;

}

