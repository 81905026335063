@font-face {
  font-family: notoLight;
  src: local('notoLight'),url(./assets/fonts/NotoSans-Light.ttf);
}
@font-face {
  font-family: notoBlack;
  src: local('notoBlack'),url(./assets/fonts/NotoSans-Black.ttf);
}

.App {
  text-align: center;
  font-family: 'notoLight';
  
}
/* @media only screen and (orientation:portrait){

  .App {

   height: 100vw !important;
   -webkit-transform: rotate(90deg);

   -moz-transform: rotate(90deg);

   -o-transform: rotate(90deg);

   -ms-transform: rotate(90deg);

   transform: rotate(90deg);
   

  }

} */


.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.notification__item--info {
  background-color: #3370c2 !important;
  border-left: 8px solid #17a2b8 !important;
}
.notification__item--info .notification__timer {
  background-color: #17a2b8 !important;
}

.notification__item--warning {
  background-color: #ffc25e !important;
  border-left: 8px solid #fae3c4 !important;
}
.notification__item--warning .notification__timer {
  background-color: #fae3c4 ! important;
}

.notification__item--success {
  background-color: #12babf !important;
  border-left: 8px solid #3370c2 !important;
}
.notification__item--success .notification__timer {
  background-color: #3370c2 ! important;
}


.notification__item--danger {
  background-color: #ff2900 !important;
  border-left: 8px solid #ff6600 !important;
}
.notification__item--danger .notification__timer {
  background-color: #ff6600 !important;
}


.notification__item--default {
  background-color: #3370c2 !important;
  border-left: 8px solid #17a2b8 !important;
}
.notification__item--default .notification__timer {
  background-color: #17a2b8;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 20s linear; */
  }
}





/* .AppContainer{ */
  /* display: block; */
  /* position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100% !important;
} */
/* .container {
  height: 500px !important;
  display: block !important;
  position: relative !important;
  width: 100% !important;
} */

body::backdrop {
  background-color: #2b3345 !important;
}



.App-header {
  background-color: #212936;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f0f2f6;
}
.App-header-login {
  background-color: #212936;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: #f0f2f6;
}
.App-Footer {
  background-color: #212936;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #f0f2f6;
}
.App-link {
  color: #61dafb;
}
.flexible-modal {
  position: absolute;
  z-index: 1;
  border: 0px !important;
  background: rgba(0, 0, 0, 0.0) !important;
}
.flexible-modal-mask {
  position: fixed;
  height: 100%;
  background: rgba(0, 0, 0, 0.0) !important;
  top:0;
  left:0;
  right:0;
  bottom:0;
}
.flexible-modal-resizer {
  position:absolute;
  right:0;
  bottom:0;
  cursor:se-resize;
  margin:1px !important;
  /* border: 0px !important; */
  border-bottom: solid 2px #333;
border-right: solid 2px #333;
}
.flexible-modal-drag-area{
  background: rgba(0, 0, 0, 0.0) !important;
  height: 50px;
  position:absolute;
  right:0;
  top:0;
  cursor:move;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
