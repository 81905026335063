
.reflexPlayer{

    /* min-height: 500px !important; */
    /* position: absolute; */
    /* top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
    /* display: flex; */
    background-color: #2b3345;
    overflow: hidden;
    
    

}
.player>h1 {
    color: #f0f2f6;
    background-color: #212936;
    opacity: 0.5;
}
