
/* .pointer {
    cursor: pointer;
} */

#closeButton {
    position: absolute;
    top: -3px;
    right: 0;
    z-index: 999;
}

#name-error-text {
    color: #fd6d5f;
    font-weight: bold;
    text-align: center;
}

/* #nicknameForm {
    padding: 10px;
} */
.fullscreen {
    top: 40px;
}

.streamComponent {
    height: 100%;
    position: relative;
}



video {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    color: #f0f2f6;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: 'notoLight',Arial, Helvetica, sans-serif;
}

/* Contains the video element, used to fix video letter-boxing */
.OT_widget-container {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}

#input,
#label {
    color: white;
}
