
  #closeButton {
    position: absolute;
    right: 0;
    top: -8px;
    color: #f0f2f6 !important;
  }
  
  .message-wrap {
    padding: 0 15px;
    height: calc(100% - 80px);
    overflow: auto;
    background-color: #d7d9d8 !important;
  }
  
  .message {
    position: relative;
    padding: 7px 0;
  }
  .user-img {
    position: absolute;
    border-radius: 45px;
    width: 65px;
    height: 65px;
    top: 31px;
  }
  
  .msg-detail {
    width: calc(100% - 65px);
    display: inline-block;
  }
  
  .msg-detail p {
    margin: 0;
    font-size: 15px;
  }
  
  .msg-info p {
    font-size: 0.8em;
    color: #000000;
    font-style: italic;
  }
  
  .msg-content {
    position: relative;
    margin-top: 5px;
    border-radius: 5px;
    padding: 8px;
    color: #000000;
    width: auto;
    max-width: 80%;
    
  }
  
  .text {
    word-break: break-word;
    color: #000000;
    
  }
  
  /* Start message from other user */
  
  .message.left .msg-detail .msg-info {
    text-align: left;
    margin-left: -45px;
  }
  .message.left .msg-detail  {
    margin-left: 15px;
  }
  
  .message.left .user-img {
    left: -5px;
    background-color: #f0f2f6;
    position: absolute;
  }
  
  .message.left .msg-detail .msg-content {
    background-color: #f0f0f0;
    float: left;
  }
  .message.left .msg-detail .msg-content span.triangle {
    left: 0px;
    bottom: -10px;
    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    border-left:10px solid #f0f0f0; 
    display: block;
    position: absolute;
    
  }
  
  /* End message from other user */
  
  /* Start my messages */
  
  .message.right .msg-detail .msg-info {
    text-align: right;
    margin-right: -40px;
  }
  .message.right .msg-detail {
    margin-right: 15px;
  }
  .message.right .user-img {
    right: -5px;
    background-color: #ffc25e;
    position: absolute;
  }
  
  .message.right .msg-detail .msg-content {
    background-color: #ffc25e;
    float: right;
  }
  .message.right .msg-detail .msg-content span.triangle {
    bottom: -10px;
    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    border-right:10px solid #ffc25e; 
    right: 0px;
    display: block;
    position: absolute;
  }
  
  /* End my messages */
  
  #messageInput {
    bottom: 0px;
    width: 100%;
    background-color: #f0f2f6;
    text-align: center;
    padding: 10px 0px;
    height: 50px;
  }
  #messageInput input {
    width: 90%;
    height: 100%;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 14px;
    margin-left: -6%;
    color: #000000;
  }
  
  #sendButton {
    background-color: rgba(0,0,0,0);
    position: absolute;
    right: -4px;
    top: 0;
    bottom: 0;
    margin: auto;
    box-shadow: none !important;
  }
  #sendButton {
    margin-left: 3px !important;
    margin-bottom: 2px !important;
  }
  
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #6b6b6b;
  }
  
  .chatComponentLight ::-webkit-scrollbar-thumb {
    background-color: #eeeeee !important;
  }


  